// change the theme
$primary:       #dc3545;
$secondary:     #e8e8e8;
/*
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-900 !default;
*/


// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(181, 181, 181);
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgb(181, 181, 181);
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
  color: rgb(181, 181, 181);
}

.MainContainer {
  padding: 20px;
  max-width: 1260px;
  margin: 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h2 {
  border-left: 4px solid $primary;
  padding-left: 20px;
  small {
    display: block;
    padding: 4px 0;
    font-size: .5em;
    color: $dark !important;
    opacity: .6;
  }
}

dt {
  color: #bcadad;
  font-size: small;
  border-bottom: 1px solid #efdfdf;
  margin-bottom: 5px;
}

dd small {
  font-size: .7em;
}

.custom-input-file {
  position: relative;
}

.custom-input-file .btn {
  position: absolute;
  width: 100%;
  max-width: 300px;
}

.custom-input-file input {
  opacity: 0.01;
}

.text-info {
  color: rgb(1, 105, 161) !important;
}

.form-check-inline input {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-top: 4px !important;
}

.film-slider {
  background-color: var(--bs-success-bg-subtle) !important;
  padding-bottom: 0;
  .film-list {
    & {
      overflow-x: auto;
      white-space: nowrap;
      padding-bottom: 15px;
      display: block;
    }
    
    .--item {
      display: inline-block;
      width: 120px;
    }
  }
}

.no-link a, a.no-link {
  text-decoration: none;
  color:#000;
  &:hover {
    color: $primary;
    text-decoration: underline;
  }
}

.card {
  .card-link {
      text-decoration: none !important;
  }
}

.film-list .card {
  height: calc(100% - 20px);
  padding-bottom: 30px;
  position: relative;
  &.wide {
    min-height: 252px;
    height:fit-content;
    margin-bottom: 30px;
    padding-right: 40%;
    .card-img-top {
      position: absolute;
      right: 0;
      width: 40%;
      min-height: 250px;
      height: 100%;
      border-radius:  0 5px 5px 0;
    }
  }
}

.film-slider .film-list.single {
  .--item {
    width: 100%;
    display: block;
  }
}

.film-list .card .card-img-top {
  object-fit: cover;
  height: 20vh;
}

.film-list .top {
  font-size: 1.3em;
  color: #CCC;
  text-shadow: 0 4px 2px rgba(0,0,0,.5);
  padding-top: 6px;
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
  radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
  border-radius: 0 6px 100% 0;
  width: 50px;
  height: 50px;
  text-align: center;
  position: absolute;
  left: -5px;
  top: -4px;
  box-shadow: 0 4px 2px rgba(0,0,0,.5);
}

.film-list.small {
  .card .card-img-top {
    height: 20vh;
  }
  .card-body {
    padding: 2px 6px !important;
  }
}

.film-list.medium {
  .card .card-img-top {
    height: 35vh;
  }
  .card-body {
    padding-top: 5px !important;
    padding-bottom: 25px !important;
  }
}

.film-list .card .btn {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-radius: 0 0 5px 5px;
  border: 0px none;
}

.online-options {
  .card {
    height: calc(100%);
  }
}

.deactivated {
  border-style: dashed;
  border-width: 2px;
  .top {
    display: none;
  }
}
.deactivated > * {
  opacity: .2;
}

.list-mail {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #CCC;
}

.list-mail td {
  width: 33%;
}

a.selected {
  color: #000;
  font-weight: bold;
  text-decoration: none;
  padding-left: 18px;
  position: relative;
}

a.selected::before {
  content: '\25BA';
  margin-left: '-10px';
  position: absolute;
  left: 0;
  color: #dc3545;
}

.text-woman {
  color: rgb(149, 4, 197) !important;
}

i.icon, .btn i {
  font-style: normal;
  font-weight: bold;
  line-height: 0;
  vertical-align: baseline;
  font-size: 1.5em;
  &.text-warning {
    vertical-align: middle;
  }
}

.alert {
  padding-left: 90px;
  position: relative;
  overflow: hidden;
 svg {
    opacity: 0.2;
    font-size: 80px;
    position: absolute;
    top: 28px;
    left: -10px;
    margin-top: -40px;
    }
}

svg.icon {
  font-size: 1.4rem;
  vertical-align: bottom;
}

.card {
  &.bg-success {
    background: var(--bs-success-bg-subtle) !important;
  }
}

.btn svg {
  margin-right: 6px;
}

.form-check-input:checked {
  background-color: $success !important;
  border-color: $success !important;
}

.form-check-input:focus {
  border-color: #6668 !important;
  outline: 0;
  box-shadow: none !important;
}

.form-switch {
  .form-check-input {
    background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27%23666%27/></svg>") !important;
  }
  .form-check-input:checked {
    background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27%23fff%27/></svg>") !important;
  }
  
}

.table-list {
  & {
    border-top: 16px solid #FFF;
  }
  .--item {
    height: calc(100%);
    border-bottom: 1px solid #CCC;
  }
  .bg-success {
    background-color: var(--bs-success-bg-subtle) !important;
  }
  .row:first-child > * { 
    padding-left: 2px;
    padding-right: 2px;
  }
  h5 {
    opacity: .4;
    font-size: .9rem;
  }
  pre {
    border: 1px solid rgba(0,0,0,.5);
    max-height: 140px;
    overflow-y: auto;
    background: rgba(255,255,255,.8);
  }
  .text-dark {
    opacity: .2;
  }
}


.tooltip-arrow {
  margin-top: 6px !important;
}

.toast-container {
  position: sticky;
  top: 100px;
}

g {
  tspan {
    fill: #FFF !important;
    font-weight: bold;
    font-size: 1.5rem !important;
  } 
}

.stats-legend {
  font-size: .85em;
  dt {
    width: 18px;
    height: 18px;
    float: left;
    clear: both;
    &.red {
      background: red;
    }
    &.green {
      background: green;
    }
    &.brown {
      background: brown;
    }
  }
  dd {
    margin :0 10px 0 5px;
    float: left;
  }
}